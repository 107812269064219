import "animate.css/animate.css";
import "lightgallery.js/dist/css/lightgallery.css";

import "./index.scss";

import "regenerator-runtime/runtime";

import "./scripts/forms";
import "./scripts/accordion";
import "./scripts/components/copyText";

import "./scripts/blog/script";
import "./scripts/blog/sort";

import "./scripts/team/script";

import "./scripts/subscribe/subscribe";
import "./scripts/subscribe/unsubscribe";

import "./scripts/order_license/order_license";

import "./scripts/for-managers/index";
import "./scripts/for-managers/slider";

$(".dropdown").mouseenter(function () {
  $(this).find(".dropdown-menu").addClass("show");
  $(".b-header").removeClass("b-header-opacity");
});

$(".dropdown").mouseleave(function () {
  $(this).find(".dropdown-menu").removeClass("show");
});

$(document).ready(function () {
  // Слайдер с инструментами, куда можно интегрировать PVS-Studio

  if(typeof Swiper !== "undefined"){
    new Swiper(".static-analyzer .instruments-slider", {
      navigation: {
        nextEl: ".static-analyzer .instruments-slider-next",
        prevEl: ".static-analyzer .instruments-slider-prev",
      },
      speed: 400,
      loop: true,
      autoHeight: true,
      spaceBetween: 30,
      breakpoints: {
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1200: {
          slidesPerView: 6,
          slidesPerGroup: 6,
        },
      },
    });

    // Слайдер "Доверьте поиск ошибок"
    new Swiper(".static-analyzer .errors-slider", {
    navigation: {
      nextEl: ".static-analyzer .errors-slider-next",
      prevEl: ".static-analyzer .errors-slider-prev",
    },
    allowTouchMove: false,
    speed: 400,
    loop: true,
    autoHeight: false,
    spaceBetween: 30,
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
    on: {
      slideChange: function ({ activeIndex }) {
        // Чтобы предотврадить баги отображения слайдера
        // мы кликаем на открытые раскрывашки, чтоб закрыть их
        $(".static-analyzer .errors-slider .accordion-btn.opened").click();
      },
    },
  });
  }

  setErrorsSliderCardsMinHeight();

  $(window).on("resize", setErrorsSliderCardsMinHeight);
});

//** Устанавливает минимальную высоту карточек слайдера errors-slider равной максимальной */
function setErrorsSliderCardsMinHeight() {
  const cardsWrappers = $(".errors-slider-wrapper__slide");
  const cards = $(".errors-slider-wrapper__slide .pvs-card");

  let maxHeight = -1;
  cardsWrappers.each((index, wrapper) => {
    const wrapperHeight = $(wrapper).height();
    if (wrapperHeight > maxHeight) {
      maxHeight = wrapperHeight;
    }
  });

  cards.each((index, card) => {
    $(card).css("min-height", maxHeight);
  });
}

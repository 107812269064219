import axios from "axios";

function subscribeAction (is_subscribe=true){

    const subscribeButtonElem = $('#comments_subscribe');
    const subscribeParagraph = $('#comments_subscribe_p');
    const unsubscribeButton = $('#comments_unsubscribe');
    const unsubscribeParagraph = $('#comments_unsubscribe_p');
    const activeFavorite = $('.blog-favorite [data-active="True"]');
    const activeSubscribe = $('.blog-subscribe [data-active="True"]');
    const inactiveFavorite = $('.blog-favorite [data-active="False"]');
    const inactiveSubscribe = $('.blog-subscribe [data-active="False"]');
    const form = $('form.comments_subscribe')


    let data = new FormData();

    //if <activeSubscribe> is visible - invert data
    if (!activeSubscribe.hasClass('d-none')){
        is_subscribe = !is_subscribe
    }

    if (is_subscribe){
        data.append("is_subscribe", "True");
    }
    else{
        data.append("is_subscribe", "False");
    }
    data.append("content_id", form.find('[name = "content_id"]').val());
    data.append("user", form.find('[name = "pa_user"]').val());
    data.append("lang", form.find('[name = "lang"]').val());
    let url = form.attr('action');

    axios.post(url, data, {withCredentials: true})
    .then(res => {
        if (res.data === 'deleted'){

            activeSubscribe.addClass('d-none');
            inactiveSubscribe.removeClass('d-none');

            activeFavorite.addClass('d-none');
            inactiveFavorite.removeClass('d-none');

            subscribeButtonElem.show();
            subscribeParagraph.show();
            unsubscribeButton.hide();
            unsubscribeParagraph.hide();
        }
        // subscribe & favorite
        else if(res.data === 'True') {
            activeSubscribe.removeClass('d-none');
            inactiveSubscribe.addClass('d-none');

            activeFavorite.removeClass('d-none');
            inactiveFavorite.addClass('d-none');

            subscribeButtonElem.hide();
            subscribeParagraph.hide();
            unsubscribeButton.show();
            unsubscribeParagraph.show();
        }
        // no subscribe, but still favorite
        else if(res.data === 'False'){
            activeSubscribe.addClass('d-none');
            inactiveSubscribe.removeClass('d-none');

            activeFavorite.removeClass('d-none');
            inactiveFavorite.addClass('d-none');

            subscribeButtonElem.show();
            subscribeParagraph.show();
            unsubscribeButton.hide();
            unsubscribeParagraph.hide();
        }
        else {
            console.log('invalid response')
        }
    }).catch(err =>{
        console.log('err');
    })
  }

$(document).ready(function () {
  $(".b-blog__tab-search").on("click", function () {
    $(this).toggleClass("b-blog__tab--active-search");
    $(".b-blog__search").toggle();
  });

  $(".b-blog__sub-form").on("submit", function (e) {
    let error = false;
    e.preventDefault();

    const requiredFields = $(this).find(".required");
    if (requiredFields.length > 0) {
      requiredFields.map((i, field) => {
        const value = $(field).find("input").val();
        if (
          value === "" ||
          (field.classList.contains("email"))
        ) {
          $(field).addClass("error");
          error = true;
        } else {
          $(field).removeClass("error");
        }
      });
    }

    if (!error) {
      $(this).addClass("b-form--submitted");
      const email = $(this).find('input[type="text"]').val();
      const type = $(this)
        .find('input[type="text"]')
        .attr("data-subscription-type");
      const inp_email = $(this).find('input[type="text"]').val("");
      inp_email.prop('disabled', true);
      $(".b-blog__sub-form input").css('cursor', 'wait')
      $(".b-blog__sub-form input[type='text']").css('background', '#ccc')

      $('.b-blog__sub-button input').addClass('inactive_button').removeClass('active_button')

      axios
        .post("/api/subscribe/", {
          lang: window.lang,
          email: email,
          checkboxes: [type],
        })
        .then((res) => {
          const text = res.data.result_text;
          $(this).find(".b-blog__sub-success").show().text(text);
          $('.b-blog__sub-button input').addClass('inactive_button').removeClass('active_button')
          inp_email.prop('disabled', false);
          $(".b-blog__sub-form input").css('cursor', 'auto')
          $(".b-blog__sub-form input[type='text']").css('background', '#fff')
        })
        .catch((err) => {
          $('.ajax-clean-after').parent().removeClass('error')
          $('.ajax-clean-after').remove()
          let text = err.response.data.error[window.lang]
          let el = document.getElementById('ajax_whatsnew_form_email')
          el.value = err.response.data.email
          el.parentElement.classList.add('error')
          inp_email.prop('disabled', false);
          $(".b-blog__sub-form input").css('cursor', 'auto')
          $(".b-blog__sub-form input[type='text']").css('background', '#fff')
          el.insertAdjacentHTML('afterend',
              "<div class=\"error ajax-clean-after\"><img src=\"https://files.pvs-studio.com/static/images/icons/error.png\"> " + text + "</div>"
          );
        });
    }
  });

  $('form.comments_subscribe').submit(function (e) {
    e.preventDefault();
    subscribeAction(true);
  })
  $('.blog-subscribe').click(()=>{subscribeAction(true)})
  $('.blog-favorite').click(()=>{subscribeAction(false)})
});

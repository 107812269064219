$(document).ready(function () {
  $(document).on("click", ".accordion-btn", toggleAccordionHandler);
});

function toggleAccordionHandler() {
  const dataUid = $(this).attr("data-acc");
  const parentClass = `.${$(this).attr("data-parent")}`;
  const contentElem = $(this)
    .parents(parentClass)
    .find(`.accordion-content[data-acc="${dataUid}"]`);

  const maxHeight = contentElem.css("max-height").replace("px", "");
  if (parseInt(maxHeight) > 0) {
    contentElem.css("max-height", "0px");
    $(this).removeClass("opened");
  } else {
    contentElem.css("max-height", contentElem[0].scrollHeight + "px");
    $(this).addClass("opened");
  }
}

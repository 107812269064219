document.addEventListener("DOMContentLoaded", () => {
  AOS();
});

function AOS() {
  const graphAnimateAnchor = document.querySelectorAll("#graph-animate-anchor");
  const graph = document.querySelector(".static-analyzer-graph");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        graph.classList.add("show");
      }
    });
  });

  graphAnimateAnchor.forEach((el) => observer.observe(el));
}

class CopyText {
  constructor() {
    this.#init();
  }

  #init() {
    this.copyTriggers = document.querySelectorAll(`[data-copy-text-target-id]`);

    this.copyTriggers.forEach((trigger) => {
      trigger.addEventListener(`click`, () => {
        this.copyText(trigger);
      });
    });
  }

  copyText(trigger) {
    const targetId = trigger.dataset.copyTextTargetId;
    const targetElem = document.getElementById(targetId);

    const textToCopy = targetElem.dataset.copyText || targetElem.textContent;

    try {
      navigator.clipboard.writeText(textToCopy);
    } catch (error) {
      console.error(error);
    }
  }
}

new CopyText();

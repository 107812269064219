import getUrlGetParam from "../utils/getUrlGetParam";

const sort = $(`.b-select[data-name="search-sort"]`);
const form = sort.parents("form");
const input = form.find('[name="sort"]');
const sortByInitial = getUrlGetParam("sort");
const placeholder = sort.find(".placeholder");
const items = sort.find(".b-select__item");

initSort();

// Раскрывание селекта
sort.click(function () {
  sort.toggleClass("b-select--active");
});

// Клик на селект айтем
items.click(function () {
  const value = $(this).attr("data-value");
  const title = $(this).text();

  selectOption(value, title);

  form.submit();
});

// Занесение значения из урла, если есть
function initSort() {
  if (!sortByInitial) return;

  items.each((index, item) => {
    const itemValue = $(item).attr("data-value");
    const itemText = $(item).text();

    if (itemValue !== sortByInitial) return;

    selectOption(itemValue, itemText);
  });
}

// Выбор опции в селекте без сабмита формы
function selectOption(value, title) {
  sort.addClass("b-select--confirm");
  sort.attr("data-value", value);
  placeholder.text(title);

  input.val(value);
}

$(".js-switch-col").click(function () {
  const col = $(this).attr("data-col");

  $(".js-switch-col").addClass("pvs-button--inactive");
  $(this).removeClass("pvs-button--inactive");

  $(`.b-licenses .tbl .team`).removeClass("active");
  $(`.b-licenses .tbl .enterprise`).removeClass("active");
  $(`.b-licenses .tbl .${col}`).addClass("active");
});

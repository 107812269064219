import isEmail from "validator/es/lib/isEmail";

// region Validation
$(document).ready(function () {
  const validateInputs = $("input.js-validate");

  validateInputs.each((index, input) => {
    $(input).on("input", switchValidation);
  });
});

function switchValidation(event) {
  const input = event.target;
  const inputType = event.target.type;

  switch (inputType) {
    case "email":
      validateInput(input, isEmail);
      break;

    default:
      break;
  }
}

function validateInput(input, validationFunction) {
  const inputValue = input.value;
  const inputBox = $(input).parents(".form-input");
  const btnSubmit = inputBox.parents("form").find("button[type='submit']");

  const isValid = validationFunction(inputValue);

  if (isValid) {
    inputBox.removeClass("error");
    btnSubmit.prop("disabled", false);
  } else {
    inputBox.addClass("error");
    btnSubmit.prop("disabled", true);
  }
}
//endregion Validation

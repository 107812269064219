import isEmail from "validator/es/lib/isEmail";
import {createStore} from "@reduxjs/toolkit";
import axios from "axios";

const inputEmail = $('.subscribe #ajax_whatsnew_form_email')
const btnSubscribe = $('.subscribe #btn-subscribe')
const checkboxesSubscribeType = $(".subscribe [name='subscribe-type']")
const subscribeForm = $('form.b-blog__sub-form')

const initialState = {
  email: {
    isValid: false,
    value: null
  },
  checkboxes: {
    isChosen: false,
    value: []
  }
}
function validationReducer(state = {...initialState}, action) {
  switch (action.type) {
    case 'changeEmail':
      return {
        ...state,
        email: {
          isValid: action.payload.isValid,
          value: action.payload.value
        }
      }
    case 'clickCheckbox':
      return {
        ...state,
        checkboxes: {
          isChosen: action.payload.isChosen,
          value: action.payload.value
        }
      }
    default:
      return state
  }
}

let store = createStore(validationReducer)

store.subscribe(() => {
  const isEmailValid = !inputEmail.parent().hasClass('error');
  const isCheckboxSelected = store.getState().checkboxes.isChosen

  if (!isEmailValid) {
    inputEmail.addClass('incorrect')
  } else {
    inputEmail.removeClass('incorrect')
  }

  if (isEmailValid && isCheckboxSelected) {
    btnSubscribe.prop('disabled', false)
  } else {
    btnSubscribe.prop('disabled', true)
  }
})

inputEmail.change(function() {
  const value = $(this).val()
  const isValid = isEmail(value)

  store.dispatch({ type: 'changeEmail', payload: { isValid, value } })
})

checkboxesSubscribeType.change(function() {
  const checkboxes = getCheckboxesValue()

  store.dispatch({ type: 'clickCheckbox', payload: {...checkboxes} })
})

subscribeForm.submit(function() {
  btnSubscribe.prop('disabled', true)

  axios.post('/api/subscribe/', {
    lang: window.lang,
    email: store.getState().email.value.trim(),
    checkboxes: store.getState().checkboxes.value
  }).then(res => {
    $('.pvs-content-card').html(res.data.result)
  }).catch(err => {
    console.log(500, err)
  })
})

const getCheckboxesValue = () => {
  let isOneCheckboxActive = false
  let value = []

  checkboxesSubscribeType.each((i, elem) => {
    if ($(elem).is(':checked')) {
      isOneCheckboxActive = true
      value = [...value, $(elem).val()]
    }
  })

  return { isChosen: isOneCheckboxActive, value }
}
// test
import { createStore } from "@reduxjs/toolkit";
import getUrlGetParam from "../utils/getUrlGetParam";
import axios from "axios";
import getUrlTokenParam from "../utils/getUrlTokenParam";

const btnSubscribe = $('.unsubscribe #btn-unsubscribe')
const checkboxesSubscribeType = $(".unsubscribe [name='subscribe-type']")

const initialState = {
  email: getUrlGetParam('email'),
  checkboxes: {
    isChosen: false,
    value: []
  }
}
function validationReducer(state = {...initialState}, action) {
  switch (action.type) {
    case 'clickCheckbox':
      return {
        ...state,
        checkboxes: {
          isChosen: action.payload.isChosen,
          value: action.payload.value
        }
      }
    default:
      return state
  }
}

let store = createStore(validationReducer)

store.subscribe(() => {
  const isCheckboxSelected = store.getState().checkboxes.isChosen

  if (isCheckboxSelected) {
    btnSubscribe.prop('disabled', false)
  } else {
    btnSubscribe.prop('disabled', true)
  }
})

checkboxesSubscribeType.change(function() {
  const checkboxesValue = getCheckboxesValue()

  store.dispatch({ type: 'clickCheckbox', payload: { ...checkboxesValue }})
})

btnSubscribe.click(function() {
  axios.post('/api/unsubscribe/', {
    lang: window.lang,
    md5: getUrlTokenParam(),
    checkboxes: store.getState().checkboxes.value
  }).then(res => {
    $('.pvs-content-card').html(res.data.result)
  }).catch(err => {
    console.log(500, err)
  })
})

const getCheckboxesValue = () => {
  let isOneCheckboxActive = false
  let value = []

  checkboxesSubscribeType.each((i, elem) => {
    if ($(elem).is(':checked')) {
      isOneCheckboxActive = true
      value = [...value, $(elem).val()]
    }
  })

  return { isChosen: isOneCheckboxActive, value }
}
// test